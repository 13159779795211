@media only screen and (max-width: 1210px){
    .container-pesquisa-home h1{
        padding-top: 17%;
    }
}

@media only screen and (max-width: 1027px){
    .container-doacao-conteudo-home h2{
        font-size: 35px;
        padding-bottom: 30px;
    }

    .container-doacao-conteudo-home a{
        font-size: 19px;
    }
}

@media only screen and (max-width: 900px){

    .container-pesquisa-home{
        height: 600px;
        width: 100%;
        margin-top: 10px;
        padding-top: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        background-size: cover; 
        background-position: initial;
        background-position: center center;
    }

    .container-intro-home{
        display: flex;
        flex-flow: row wrap;
    }

    .container-fundacao-home{
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        margin-right: 0px;
    }

    .container-fundacao-home .btn{
        width: fit-content;
        margin-bottom: 5%;
    }

    .container-doacao-home{
        display: flex;
        width: 100%;
    }

    .container-doacao-conteudo-home h2{
        font-size: 40px;
        padding-bottom: 25px;
    }

    .container-doacao-conteudo-home a{
        font-size: 20px;
    }
}

@media only screen and (max-width: 600px) {

    .container-pesquisa-home{
        height: 600px;
        background-size: cover; 
        background-position: center center;
    }

    .container-pesquisa-home h1{
        font-size: 50px;
    }

    .container-pesquisa-home h2{
        font-size: 20px;
    }

    .container-doacao-home{
        display: flex;
        flex-flow: column wrap;
    }

    .container-doacao-home img{
        width: 100%;
        height: 250px;
    }

    .container-doacao-conteudo-home{
        width: 100%;
        padding-bottom: 25px;
        padding-left: 15px;
    }
}