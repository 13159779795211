@media only screen and (max-width: 1213px){
    .container-titulo-escalas{
        margin: 90px 5% 0px 5%;
    }

    .container-especialidades .loading-overlay{
        padding: 100px 0px 100px 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 748px){
    .container-especialidades{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 3% 5% 5% 5%;
    }

    .container-especialidades-right, .container-especialidades-left{
        width: 100%;
    }
}    

