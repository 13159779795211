@media only screen and (max-width: 600px){
    footer{
        display: flex;
        flex-flow: column wrap;
    }

    .container-dados-redes-footer{
        display: flex;
        flex-flow: column wrap;
        margin-bottom: 12px;
        margin-top: 20px;
    }

    .container-dados-footer img{
        width: 40%;
        height: auto;
    }

    .container-redes-footer h3{
        width: 100px;
        margin-top: 28px;
    }

    .img-redes-facebook{
        width: 19px;
        height: auto;
        padding-top: 0px;
        padding-left: 0px;
    }

    .img-redes-instagram{
        width: 19px;
        height: auto;
        padding-top: 0px;
        padding-left: 0px;
    }

    .container-redes-footer #logo-prefa{
        width: 110%;
        height: auto;
        margin-top: 0px;
        margin-left: 0;
    }
}