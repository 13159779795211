@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container-pesquisa-home{
    background: linear-gradient(rgb(2, 2, 129), rgba(2, 2, 129, 0.04)),url('../../img/home.webp');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: #020381;
    margin: 0px;
    height: 600px;
    background-position: initial;
    background-color: rgba(0, 0, 0, 0.107);
}

.container-pesquisa-home h1{
    margin: 0px;
    padding-top: 12%;
    font-size: 85px;
    font-family: "Montserrat", sans-serif;    
    font-optical-sizing: auto;
    font-weight: 750;
    letter-spacing: 5px;
    color: #F6F6F6;
}

.container-intro-home{
    display: flex;
    width: 80%;
    margin: 5% 10% 5% 10%;
}

.container-fundacao-home{
    flex-flow: column wrap;
    width: 45%;
    margin-right: 5%;
}

.container-fundacao-home h2{
    text-align: left;
    border-bottom: 2px #020381;
    margin: 0px;
    margin-bottom: 2%;
}

#p2{
    margin-bottom: 30px;
}

.container-doacao-home{
    display: flex;
    flex-flow: row wrap;
    width: 50%;
}

.container-doacao-home img{
    width: 58%;
    height: auto;
    object-fit: cover;
    margin-right: 2%;
    background-color: #020381 ;
}

.container-doacao-conteudo-home{
    width: 40%;
    height: auto;
    padding: 2%;
    padding-bottom: 25px;
    background-color: #020381 ;
    font-size: 30px;
    color: #F6F6F6;
}

.container-doacao-conteudo-home h2{
    font-size: 40px;
    text-align: left;
}
