@media only screen and (max-width: 1200px){
    .container-fundacao{
        margin: 100px 5% 0px 5%;

    }
}

@media only screen and (max-width: 1000px){
    .container-fundacao{
        margin: 50px 5% 0px 5%;

    }
}

@media only screen and (max-width: 950px){
    .container-fundacao{
        display: flex;
        flex-flow: column wrap;
        margin-top: 50px;
    }

    .container-conteudo-fundacao{
        width: 94%;
        margin: 6% 6% 0% 6%;
    }

    .container-fundacao img{
        width: 90%;
        height: auto;
        margin-left: 6%;
        margin-bottom: 6%;
    }
}