.container-album-home{
    background-color: #F6F6F6;
    width: 100%;
    margin-top: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.089), 0 6px 20px 0 rgba(0, 0, 0, 0.105);
}

.container-album-home h2{
    margin: 0px;
    color: #020381 ;
    padding-top: 2%;
}

.container-instagram{
    background-color: #ebdfdf;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 96%;
    margin: 2% 2% 2% 2%;
    height: 300px;
}

.disable-sticky {
    position: static !important;
}

a.feed-powered-by-es{
    display: none !important;
}