@media only screen and (max-width: 1210px){
    
    header{
        position: absolute;
        width: 100%;
        left: 0;
        flex-direction: column; 
        align-items: flex-start; 
        background-color: rgb(245, 245, 245);
    }

    .menu{
        flex-direction: row;
        width: 100%;
    }

    header img{
        width: 55px;
        height: auto;
        margin: 0px;
        padding-left: 0;
    }

    .menu-toggle{
        position: relative;
        width: 33px;
        height: 28px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
    }

    .menu-toggle::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: rgb(52, 52, 52);;
        transform: translateY(-12px);
        box-shadow: 0 12px rgb(52, 52, 52);;
    }

    .menu-toggle::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: rgb(52, 52, 52);
        transform: translateY(12px);
    }

    header.active-menu .menu-toggle::before{
        transform: rotate(45deg);
        box-shadow: 0 0 rgb(52, 52, 52);
    }

    header.active-menu .menu-toggle::after{
        transform: rotate(315deg);
        box-shadow: 0 0 rgb(52, 52, 52);
    }

    header .navbar{
        display: none;
    }

    header.active-menu .navbar{
        display: initial;
    }

    header .navbar ul{
        display: flex;
        flex-direction: column; 
        align-items: flex-start;
        margin-top: 7px;
    }

    header .navbar, header ul{
        padding-right: 0;  
    }

    header .navbar ul li{
        width: 100%;
        padding: 18px 18px 18px 18px;
        border-top: 1px solid rgba(36, 36, 36, 0.081);
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px){
    header img{
        width: 60px;
    }

    header ul{
        padding-left: 0px;
    }

    .link{
        width: fit-content;
    }
}




