@media only screen and (max-width: 950px){
    .container-direcao{
        display: flex;
        flex-flow: column wrap;
        padding-right: 4%;
    }

    .container-conteudo-direcao{
        width: 94%;
        margin: 6% 6% 6% 6%;
    }

    .container-direcao img{
        width: 100%;
        height: auto;
    }
}