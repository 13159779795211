footer{
    background-color: rgb(245, 245, 245);
    margin: 0px;
    margin-top: 0%;
    padding: 3% 5% 2% 5%;
    color: rgb(16, 16, 16);
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.container-dados-redes-footer{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.container-dados-footer{
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    justify-content: flex-start;
    width: 80%;
}

.container-dados-footer h2, .container-dados-footer p{
    margin: 0px;
    width: fit-content;
    text-align: left;
    padding-top: 0.5%;
}

.container-dados-footer p, #copyright{
    font-size: 13px;
}



.container-redes-footer{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 20%;
}

.container-img-redes{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.img-redes-facebook{
    width: 20px;
    height: auto;
    padding-top: 3px;
    padding-left: 3px;
}

.img-redes-instagram{
    width: 20px;
    height: auto;
    padding-top: 3px;
    padding-left: 3px;
}

.link-redes{
    background-color: #020381 ;
    width: 45px;
    padding: 10px;
    border-radius: 7000px;
    margin: 0px;
    margin-left: 1.5%;
}

#copyright{
    margin: 0px;
    margin-top: 5%;
    justify-content: center;
}

.container-redes-footer #logo-prefa{
    width: 42%;
    height: auto;
    margin-top: 10px;
}
