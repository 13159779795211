.container-grupo-ouvidoria{
    display: flex;
    flex-flow: row wrap;
    width: 88%;
    margin: 4% 6% 4% 6%;
}

.container-left-ouvidoria{
    display: flex;
    flex-flow: column wrap;
    justify-items: left;
    align-items: flex-start;
}

.container-right-ouvidoria{
    display: flex;
    flex-flow: column wrap;
    justify-items: right;
    align-items: flex-end;
}

.container-right-ouvidoria img{
    width: 370px;
    margin-left: 50px;
}

.container-titulo{
    display: flex;
    flex-flow: column wrap;
    justify-content: left;
    align-items: flex-start;
    margin-bottom: 90px;
}

.container-titulo h1{
    margin: 0px;
    margin-bottom: 1.5%;
}

.container-titulo hr{
    width: 120px;
}

.container-contatos{
    display: flex;
    flex-flow: column wrap;
    justify-content: right;
    align-items: flex-end;
    margin: 4% 6% 5% 0%;
    width: 100%;
}

.container-contatos h2{
    margin-top: 0px;
    margin-bottom: 2%;
    width: fit-content;
    display: flex;
}

.container-contatos #telefone{
    color: #020381 ;
    font-size: 40px;
}

.bold{
    font-weight: 700;
}

.container-ouvidoria{
    width: 88%;
    margin: 0% 6% 8% 6%;
    padding-bottom: 3%;
    padding-top: 2%;
    background-color: #cfcfcf;
    border-radius: 11px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.051), 0 6px 20px 0 rgba(0, 0, 0, 0.056);
}

.container-ouvidoria form{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

input, select, textarea{
    width: 50%;
    border: none;
    border-radius: 11px;
    margin: 1%;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input:focus, textarea:focus, select:focus{
    outline: #2727276b solid 2px;

}

.container-ouvidoria .btn{
    width: fit-content;
}