@media only screen and (max-width: 1238px){
    .container-noticias{
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        width: 90%;
    }
}

@media only screen and (max-width: 950px) {

    .container-noticia-home,
    .container-noticias,
    .container-noticias-home {
        width: 100%; 
        padding: 0; 
    }

    .container-noticia-home {
        width: 90%; 
        margin-bottom: 20px; 
    }

    .container-noticia-home p{
        margin: 5px 5% 0px 5%;
    }

    .container-noticia-home img {
        width: 85%; 
        height: auto; 
    }

    .noticia-conteudo {
        padding: 10px; 
    }

    .container-noticia-home h3{
        margin-left: 25px;
        margin-right: 25px;
    }

    .btn-noticia{
        width: 80%;
        margin-top: 6%;
        margin-bottom: 8%;
    }
}