@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


header{
    background-color: rgb(245, 245, 245);
    margin: 0px;
    padding: 8px;
    display: flex;
    position: sticky;
    top: 0;
    align-self: flex-start;
    flex-flow: row nowrap;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    z-index: 1000;
}

header img{
    width: 71px;
    height: auto;
    margin: 0px;
    padding-left: 3%;
}

header img:hover{
    opacity: 0.7;
}

header ul, .navbar{
    margin: 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 0px;
    display: flex;
    flex-flow: row-reverse;
    justify-content: right;
    align-items: center;
}

li{
    margin-left: 20px;
    list-style: none;
}

header ul li {
    position: relative;
    border-right: rgba(128, 128, 128, 0.91);
    display: flex;
    align-items: center;
    justify-content: center;
}

header ul a:not(.btn)::after{
    content: "";
    background-color: #020381;
    height: 3px;
    width: 0%;
    display: block;
    margin: 0 auto;
    transition: 0.3s;
    border-radius: 5px;
}

header ul a:hover::after{
    width: 70%;
}

.link{
    color: rgb(16, 16, 16);
    text-decoration: none;
    font-weight: 600;
    width: fit-content;
    padding: 5px 7px 5px 7px;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    text-transform: uppercase;
    font-size: small;
}

.link:hover{
    font-weight: 700;
    border-radius: 2px;
    color: #020381 ;
    transition: 0.3s;
}

.active-page{
    padding: 5px 7px 5px 7px;
    font-weight: 700;
    border-radius: 2px;
    color: #020381 ;
}

h1, h2{
    text-align: center;
}