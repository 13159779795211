.loading-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px;
}

.container-escala{
    width: 80%;
    margin: 5% 10% 5% 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
}

.container-titulo-escala{
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin-bottom: 20px;
}

.container-escala h1{
    width: fit-content;
    margin: 0px;
    margin-top: 0%;
    margin-bottom: 8px;
}

.container-ano-pdfs{
    background-color: rgba(128, 128, 128, 0.107);
    border-radius: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.container-grupo-pdfs{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 0px 0px 0px 0px;
}

.container-pdfs-left{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
}

.container-pdfs-right{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 75%;
}

.container-pdfs-left a, .container-pdfs-right a{
    width: 80%;
    margin: 5px 0px 5px 0px;
}
