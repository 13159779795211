*{
    box-sizing: border-box;
}

.btn{
    background-color: #020381 ;
    padding: 8px 17px 9px 17px;
    text-decoration: none;
    color: #F6F6F6;
    font-weight: 600;
    border-radius: 20px;
    text-align: center;
}

.btn:hover, .btn-black:hover, .btn-white:hover{
    opacity: 0.75;
}

.btn-white{
    background-color: #F6F6F6;
    color: rgb(16, 16, 16);
    font-size: 20px;
    font-weight: 700;
}

.btn-black{
    background-color: rgb(38, 38, 38);
    color: #F6F6F6;
}

hr{
    border: solid 2px #020381 ;
    border-radius: 2px;
    width: 60px;
    margin: 0px;
    justify-content: flex-start;
}