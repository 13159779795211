.container-fundacao{
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    margin: 4% 5% 4% 5%;
}

.container-conteudo-fundacao{
    width: 60%;
}

.container-conteudo-fundacao h1{
    text-align: left;
    margin: 0px;
    margin-bottom: 0%;
}

.container-conteudo-fundacao p{
    margin-right: 5%;
}

.container-fundacao img{
    width: 40%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}