@media only screen and (max-width: 950px){
    .container-historia{
        display: flex;
        flex-flow: column wrap;
    }

    .container-conteudo-historia{
        width: 90%;
        margin: 6% 6% 6% 6%;
    }

    .container-conteudo-historia h1, .container-conteudo-historia p, .container-conteudo-historia hr{
        text-align: left;
        margin-left: 0px;
    }

    .container-historia img{
        width: 100%;
        height: auto;
    }
}