.container-noticias-home{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2% 10% 5% 10%;
    background-color: #EAE8E8;
}

.container-noticias{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.container-noticias-home h2{
    margin-bottom: 0.5%;
}

.container-noticias-home hr{
    margin-bottom: 2%;
}

.container-noticia-home{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: 8px;
    background-color: #F6F6F6;
    padding: 20px;
    text-align: justify;
    max-width: 400px;
    min-height: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.089), 0 6px 20px 0 rgba(0, 0, 0, 0.105);
    border-radius: 9px;
    transition: all 3s ease-in-out;
}

@media only screen and (min-width: 1238px){
    .container-noticia-home:hover {
        margin-top: -20px;
        background-color: #020381;
        color: #F6F6F6;
    }

    .container-noticia-home:hover h3{
        color: #F6F6F6;
    }
}

.container-noticia-home h3{
    color: #020381 ;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}

.container-noticia-home p{
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    margin-bottom: 0;
}

.img-noticia{
    width: 100%;
    height: 190px;
    background-color: #ebdfdf;
    object-fit: cover;
}

.btn-noticia{
    margin-top: 25px;
    width: 30%;
}