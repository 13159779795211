@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 

body {
  margin: 0;
  font-family: "Poppins", 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(242, 242, 242);
}

body::-webkit-scrollbar {
  width: 7px;               
}

body::-webkit-scrollbar-track {
  background: #c4c4c615;        
}

body::-webkit-scrollbar-thumb {
  background-color: #cacacb;    
  border-radius: 20px;       
  border: 0px solid orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
